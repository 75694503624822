import React, { useState } from 'react';
import FormHeading from './FormHeading';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Input, Textarea } from '../FormsInputes.js';
import { HeaderBoarder } from '../Components/index.js';
import { addAchivement } from '../service/Services.js';

const CommunityCreation = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const [samajShow, setSamajShow] = useState([]);

  const handleChange = (fieldName, e) => {
    const value = e.target.value;
    setValue(fieldName, value, { shouldValidate: true });
  };

  const handleFileChange = (fileName, e) => {
    const file = e.target.files && e.target.files[0]; // Check if files exist
    if (file) {
      setValue(fileName, file);
    } else {
      console.error("No file selected or invalid file input.");
    }
  };

  const samaj = [
    { text: 'porwal samaj indor', id: 1 },
    { text: 'porwal samaj Bhopal', id: 2 },
    { text: 'porwal samaj Shendhwa', id: 3 },
    { text: 'porwal samaj Balwani', id: 4 }
  ];

  const onSamajSelectChange = (e) => {
    const { value } = e.target;
    try {
      const { text, id } = JSON.parse(value);
      if (text) {
        setSamajShow((prevSamajShow) =>
          prevSamajShow.some((item) => item.text === text) // Avoid duplicates
            ? prevSamajShow
            : [...prevSamajShow, { text, id }]
        );
      }
    } catch (error) {
      console.error("Error parsing value: ", error);
    }
  };

  const handleRemoveSamajShow = (indexToRemove) => {
    setSamajShow((prevSamajShow) => prevSamajShow.filter((_, index) => index !== indexToRemove));
  };

  const submit = async (data) => {
    try {
      const requiredFields = ['achievementHeading', 'achiverName', 'message', 'profilePhoto'];
      const hasMissingFields = requiredFields.some((field) => !data[field]);
      if (hasMissingFields) {
        throw new Error('All Star fields are required');
      }
      setLoading(true);

      // Create FormData object
      const formData = new FormData();
      const entryInSamaj = {
        curentSamaj: 'Indor porwal samaj',
        id: 1
      };
      formData.append('achievementHeading', data.achievementHeading);
      formData.append('samajToShow', JSON.stringify(samajShow));
      formData.append('entryInSamaj', JSON.stringify(entryInSamaj));
      formData.append('achiverName', data.achiverName);
      formData.append('message', data.message);
      formData.append('profilePhoto', data.profilePhoto);
      formData.append('photo1', data?.photo1);
      formData.append('photo2', data?.photo2);

      // Call addAchivement API
      const isNewsAdded = await addAchivement(formData);

      // Check if news added successfully
      if (isNewsAdded?.success) {
        Swal.fire({
          title: isNewsAdded.success,
          text: isNewsAdded.message,
          icon: 'success'
        });
        reset();
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error'
      });
    } finally {
      setLoading(false);
      setSamajShow([]);
    }
  };

  return (
    <>
      <HeaderBoarder heading={'Community Creation'} />
      <div className="w-100 md:px-10">
        <form onSubmit={handleSubmit(submit)} className="border-[1px] border-gray-900/10 p-4">
          <FormHeading heading={'Community Creation'} />
          <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
            {/* achievement heading */}
            <div className="sm:col-span-3">
              <Input
                label="Name"
                placeholder="Name"
                type="text"
                required="required"
                {...register('achievementHeading', {
                  required: 'इस फ़ील्ड को भरना ज़रूरी है',
                  minLength: {
                    value: 3,
                    message: 'कृपया कम से कम 3 अक्षर भरें'
                  },
                  maxLength: {
                    value: 200,
                    message: 'कृपया 200 अक्षरों से कम भरें'
                  }
                })}
              />
              {errors?.achievementHeading && (
                <p className="text-red-500 text-xs mt-1">{errors.achievementHeading.message}</p>
              )}
            </div>

            {/* achiver name */}
            <div className="sm:col-span-3">
              <Input
                label="Address"
                placeholder="Address"
                type="text"
                required="required"
                {...register('achiverName', {
                  required: 'इस फ़ील्ड को भरना ज़रूरी है'
                })}
              />
              {errors?.achiverName && (
                <p className="text-red-500 text-xs mt-1">{errors.achiverName.message}</p>
              )}
            </div>

            {/* profile Photo */}
            <div className="sm:col-span-3">
              <Input
                label="Logo"
                placeholder="Achiver Logo"
                type="file"
                required="required"
                onChange={(e) => handleFileChange('profilePhoto', e)}
              />
              {errors?.profilePhoto && (
                <p className="text-red-500 text-xs mt-1">{errors.profilePhoto.message}</p>
              )}
            </div>

            {/* phone */}
            <div className="sm:col-span-3">
              <Input
                label="Phone no"
                placeholder="Phone no"
                type="tel"
                onChange={(e) => handleFileChange('phone no', e)}
              />
              {errors?.phoneno && (
                <p className="text-red-500 text-xs mt-1">{errors.phoneno.message}</p>
              )}
            </div>

            {/* start date */}
            <div className="sm:col-span-3">
              <Input
                label="Start Date"
                placeholder="Start Date"
                type="date"
                onChange={(e) => handleFileChange('start date', e)}
              />
              {errors?.startdate && (
                <p className="text-red-500 text-xs mt-1">{errors.startdate.message}</p>
              )}
            </div>

            {/* samaj show */}
            <div
              className={`${
                samajShow.length > 0 ? 'flex' : 'hidden'
              } px-2 col-span-full h-100 flex-wrap flex gap-2 rounded-md border-0 py-1.5 p-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            >
              {samajShow.map((item, index) => (
                <div
                  className="text-center rounded-md bg-blue-500 w-max px-4 font-semibold text-white"
                  key={index}
                >
                  {item.text}
                  <span className="ml-5 cursor-pointer" onClick={() => handleRemoveSamajShow(index)}>
                    X
                  </span>
                </div>
              ))}
            </div>

            {/* end date */}
            <div className="sm:col-span-3">
              <Input
                label="End Date"
                placeholder="End Date"
                type="date"
                onChange={(e) => handleFileChange('end date', e)}
              />
              {errors?.enddate && (
                <p className="text-red-500 text-xs mt-1">{errors.enddate.message}</p>
              )}
            </div>

            {/* message */}
            <div className="col-span-full mx-2">
              <Textarea
                label="Message / संदेश"
                placeholder="Message"
                type="text"
                {...register('message', {
                  required: 'इस फ़ील्ड को भरना ज़रूरी है',
                  minLength: {
                    value: 10,
                    message: 'कृपया कम से कम 10 अक्षर भरें'
                  }
                })}
              />
              {errors?.message && (
                <p className="text-red-500 text-xs mt-1">{errors.message.message}</p>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white font-semibold py-2 px-4 mt-4 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </>
  );
};

export default CommunityCreation;
