import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Footer, Header, HeaderBoarder } from '../../../Components'; // Assuming you have these components correctly imported

import Committee from '../../Dashboard/DashPages/Committee.js';
import Rishtey from "../pages/Rishtey.js"
import { MemberProfileEditForm, Rules, Intrest, GouSewa, SamajSewa } from '../pages/Index.js';
import { useDispatch, useSelector } from 'react-redux';
import { MatrimonyForm, } from '../../../Forms/index.js';
import VivahPage from '../pages/VivahPage.js';
import { Gatividhiya } from '../../Dashboard/DashPages/Index.js';


export default function MatrimonyDashDetail() {
    const { section } = useParams();
    const isAuthenticated = useSelector(state => state?.auth);

    const text = {
        committee: "कार्यकारिणी समिति",
        // members: "समाज सदस्य",
        // mandirdarshan: "श्री लक्ष्मी नरसिंह मंदिर दर्शन",
        rishtey: "विवाह रिश्ते",
        vivahGallery: "विवाह रिश्ते",
        intrest: "पसंदीदा रिश्ते",
        // bookdharmashala: "बुक धर्मशाला",
        // programs: "कार्यक्रम",
        // bloodbank: "ब्लड बैंक",
        // donations: "डोनेशन लागा",
        // achievements: "उपलब्धियां",
        // samajcalender: "समाज कैलेंडर",
        // digitalcard: "डिजिटल कार्ड",
        // promotion: "एड प्रमोशन",
        // complaints: "शिकायतें",
        // invitations: "निमंत्रण",
        // news: "समाचार",
        // jobs: "नौकरी",
        // businessprofile: "बिजनेस प्रोफाइल",
        // registration: "रजिस्ट्रेशन",
        // artical: "आर्टिकल",
        // lagahistory: 'समाज लागा विवरण ',
        gatividhiya: "समाज गतिविधि ",
        samajSewa: "समाज सेवा",
        GouSewa: "गौ सेवा",
        // BookExchange: "पुस्तक एक्सचेंज",
        // KapadeDan: "कपडे दान"

        // gallery: "गैलरी",
        // samiti: "samiti",
        rules: "नियम"

    };

    //  कमिटी
    //   मेंबर्स
    //   मंदिर दर्शन
    //   विवाह रिश्ते
    //   बुक धर्मशाला
    //   कार्यक्रम
    //   ब्लड बैंक
    //   डोनेशन लागा
    //   उपलब्धियां
    //   समाज कैलेंडर
    //   डिजिटल कार्ड
    //   एड प्रमोशन
    //   शिकायतें
    //   निमंत्रण
    //   शोक समाचार
    //   नौकरी
    //   बिजनेस प्रोफाइल
    //   रजिस्ट्रेशन

    function renderPages(section) {
        switch (section) {
            case "committee": return <Committee />;
            // case "members": return <Members />;
            // case "mandirdarshan": return <Mandirdarshan />;
            case "rishtey": return <Rishtey />;
            case "vivahGallery": return <VivahPage />;
            case "intrest": return <Intrest />;

            // case "bookdharmashala": return <Bookdharmashala />;
            // case "programs": return <Programs />;
            // case "bloodbank": return <BloodbankPage />;
            // case "donations": return <Donations />;
            // case "achievements": return <Achievements />;
            // case "samajcalender": return <Samajcalender />;
            // case "digitalcard": return <Digitalcard />;
            // case "promotion": return <Promotion />;
            // case "suzhav": return <Suzhav />;
            // case "invitations": return <Invitations />;
            // case "news": return <News />;
            // case "jobs": return <Jobs />;
            // case "businessprofile": return <Businessprofile />;
            // case "registration": return <Registration />;
            // case "artical": return <Artical />;
            // case "careerGuidance": return <CareerGuidance />;
            // case "lagahistory": return <LagaHistory />;

            case "samajSewa": return <SamajSewa />;
            case "GouSewa": return <GouSewa />;
            // case "BookExchange": return <BookExchange />;
            // case "KapadeDan": return <KapadeDan />;
            case "gatividhiya": return <Gatividhiya />;
            // case "profileEdit": return <MatrimonyForm />;
            // case "gallery": return <Gallery />;
            // case "samiti": return <Samiti />;
            case "rules": return <Rules />;

            case "profileEdit": return (isAuthenticated?.user?.userType === 4) ? <MatrimonyForm /> : <MemberProfileEditForm />;


            default:
                break;
        }
    }
    return (
        <>

            <HeaderBoarder heading={text[section]} />
            {renderPages(section)}

            {/* <AdminDetails /> */}
        </>
    );
}
